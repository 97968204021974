import * as React from 'react'
import { Link } from '../../components/link'
import { SEO } from '../../components/seo'

type Props = {}

export default class StyleguidePage extends React.Component<Props, {}> {
  public render() {
    return (
      <>
        <SEO title="Styleguide" />
        <div className="container container--grid">
          <div className="container__row">
            <h1 className="type-h1" style={{ margin: '48px 0' }}>
              Styleguide
            </h1>
            <nav className="wysiwyg" style={{ margin: '48px 0' }}>
              <ul>
                <li>
                  <Link to="/styleguide/type">Type</Link>
                </li>
                <li>
                  <Link to="/styleguide/buttons">Buttons</Link>
                </li>
                <li>
                  <Link to="/styleguide/forms">Forms</Link>
                </li>
                <li>
                  <Link to="/styleguide/images">Image</Link>
                </li>
                <li>
                  <Link to="/styleguide/lists">Lists</Link>
                </li>
                <li>
                  <Link to="/styleguide/wysiwyg">WYSIWYG (editorial)</Link>
                </li>
                <li>
                  <Link to="/styleguide/feature-label">Feature Label</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </>
    )
  }
}
